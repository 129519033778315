import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import { graphql } from "gatsby";
import { RichText as PrismicRichText } from "prismic-reactjs";
import { lighten, rgba } from "polished";
import moment from "moment-timezone";

import linkResolver from "src/utils/linkResolver";
import serializer from "src/utils/serializer";

import {
  Box,
  Button,
  FlexCol,
  FlexGrid,
  H4,
  PageHeaderV2,
  PrismicLink,
  RichText,
  SectionWrapper,
  SEO,
  Theme,
} from "src/components";

import TicketBlock from "./_components/_ticketBlock";
import TicketList from "./_components/_ticketList";
import Features from "./_components/_features";
import Alternate from "./_components/_alternate";
import Disclaimer from "./_components/_disclaimer";

function FestivalTickets({ preview, data, setInvert, setInvertLogo }) {
  let festival = JSON.parse(data.festival.dataString);
  let activeFestival = {};
  if (data.activeFestival) {
    activeFestival = JSON.parse(data.activeFestival.dataString);
  }

  const [activeTicketCategory, setActiveTicketCategory] = useState("Adult");
  return (
    <>
      <SEO
        title={"Tickets | " + PrismicRichText.asText(festival.title)}
        description={
          festival.opengraph_description &&
          PrismicRichText.asText(festival.opengraph_description)
        }
        image={
          festival.opengraph_image &&
          festival.opengraph_image.url &&
          festival.opengraph_image.url
        }
        imageAlt={
          festival.opengraph_image &&
          festival.opengraph_image.alt &&
          festival.opengraph_image.alt
        }
      />
      <ThemeProvider
        theme={Theme({
          bg:
            festival.theme === "Dark theme"
              ? {
                  default: "#1B1A19",
                  alt: lighten(0.05, "#1B1A19"),
                  wash: lighten(0.1, "#1B1A19"),
                  reverse: "#FFF",
                }
              : undefined,
          primary: festival.festival_color,
          secondary: festival.festival_secondary_color,
          tertiary: festival.festival_tertiary_color,
        })}
      >
        <>
          <PageHeaderV2
            heading={PrismicRichText.asText(festival.tickets_title)}
            subheading={PrismicRichText.asText(festival.tickets_subtitle)}
            color="brand.primary"
            invert={true}
            setInvertLogo={setInvertLogo}
            setInvert={setInvert}
          >
            <Box p={[3, 4]}>
              {festival.map && (
                <img
                  src={festival.map.url}
                  alt={festival.map.alt}
                  width="100%"
                  height="auto"
                />
              )}
            </Box>
            {festival.start_day && festival.end_day && (
              <ThemeProvider
                theme={Theme({
                  text:
                    festival.theme === "Dark theme"
                      ? { default: "#fff", reverse: "#1B1A19" }
                      : undefined,
                  bg:
                    festival.theme === "Dark theme"
                      ? {
                          default: "#1B1A19",
                          alt: lighten(0.05, "#1B1A19"),
                          wash: lighten(0.1, "#1B1A19"),
                          reverse: "#FFF",
                        }
                      : undefined,
                  primary: festival.festival_color,
                  secondary: festival.festival_secondary_color,
                  tertiary: festival.festival_tertiary_color,
                })}
              >
                <FlexCol alignItems="center">
                  <Box px={7} py={5} textAlign="center" bg="bg.default" my={5}>
                    <H4>
                      {moment(festival.start_day).format("MMMM DD") +
                        "–" +
                        moment(festival.end_day).format("DD, YYYY")}
                    </H4>
                  </Box>
                </FlexCol>
              </ThemeProvider>
            )}
          </PageHeaderV2>
        </>
      </ThemeProvider>
      <ThemeProvider
        theme={Theme({
          text:
            festival.theme === "Dark theme"
              ? {
                  default: "#fff",
                  body: rgba("#fff", 0.75),
                  alt: rgba("#fff", 0.5),
                  reverse: "#1B1A19",
                  reverseBody: rgba("#1B1A19", 0.75),
                  reverseAlt: rgba("#1B1A19", 0.5),
                }
              : undefined,
          bg:
            festival.theme === "Dark theme"
              ? {
                  default: "#1B1A19",
                  alt: lighten(0.05, "#1B1A19"),
                  wash: lighten(0.1, "#1B1A19"),
                  reverse: "#FFF",
                }
              : undefined,
          primary: festival.festival_color,
          secondary: festival.festival_secondary_color,
          tertiary: festival.festival_tertiary_color,
        })}
      >
        <>
          {((activeFestival.ongoing_festival &&
            activeFestival.ongoing_festival.uid !== data.festival.uid) ||
            activeFestival.festival_online === "No") && (
            <SectionWrapper
              id="ticketsNotAvailable"
              maxWidth={2}
              py={[8, 9, 10]}
              bg="bg.default"
            >
              {activeFestival.festival_offline_message &&
                activeFestival.festival_offline_message[0] && (
                  <Box mb={4} maxWidth={600} mx="auto">
                    <RichText textAlign="center" fontSize={3}>
                      {activeFestival.festival_offline_message &&
                        PrismicRichText.asText(
                          activeFestival.festival_offline_message
                        )}
                    </RichText>
                  </Box>
                )}
              {activeFestival.festival_offline_button_text &&
                activeFestival.festival_offline_button_link &&
                activeFestival.festival_offline_button_link.link_type && (
                  <FlexCol alignItems="center">
                    <Button
                      children={activeFestival.festival_offline_button_text}
                      as={PrismicLink}
                      link={activeFestival.festival_offline_button_link}
                      variant={"primary"}
                    />
                  </FlexCol>
                )}
            </SectionWrapper>
          )}
          {((activeFestival.festival_online === "Yes" &&
            activeFestival.ongoing_festival &&
            activeFestival.ongoing_festival.uid === data.festival.uid) ||
            preview) && (
            <>
              <SectionWrapper
                id="tickets"
                maxWidth={2}
                py={[8, 9, 10]}
                bg="bg.default"
              >
                <FlexGrid gutterX={[5, 7, 7, 9]} gutterY={[7]}>
                  <FlexCol
                    width={[1, null, null, festival.features ? 2 / 3 : 1]}
                  >
                    {festival.show_tickets_as === "List" && (
                      <TicketList
                        map={festival.map}
                        ticketLink={festival.ticket_link}
                        tickets={festival.ticket_body}
                        illustrations={festival.illlustrations}
                        activeTicketCategory={activeTicketCategory}
                        setActiveTicketCategory={setActiveTicketCategory}
                        childrenText={
                          festival.children_disclaimer &&
                          PrismicRichText.asText(festival.children_disclaimer)
                        }
                      />
                    )}
                    {festival.show_tickets_as !== "List" && (
                      <TicketBlock
                        map={festival.map}
                        ticketLink={festival.ticket_link}
                        tickets={festival.ticket_body}
                        illustrations={festival.illlustrations}
                        activeTicketCategory={activeTicketCategory}
                        setActiveTicketCategory={setActiveTicketCategory}
                        childrenText={
                          festival.children_disclaimer &&
                          PrismicRichText.asText(festival.children_disclaimer)
                        }
                      />
                    )}
                  </FlexCol>
                  {festival.features && (
                    <FlexCol
                      width={[1, null, null, 1 / 3]}
                      justifyContent={
                        festival.show_tickets_as === "List" ? "start" : "center"
                      }
                    >
                      <Features
                        title={
                          festival.feature_title &&
                          PrismicRichText.asText(festival.feature_title)
                        }
                        features={festival.features}
                      />
                    </FlexCol>
                  )}
                  {festival.features && (
                    <FlexCol width={[1, null, null, 2 / 3]}>
                      <ThemeProvider
                        theme={Theme({
                          text:
                            festival.theme === "Dark theme"
                              ? {
                                  default: "#fff",
                                  body: "#fff",
                                  reverse: "#1B1A19",
                                }
                              : undefined,
                          bg:
                            festival.theme === "Dark theme"
                              ? {
                                  default: "#1B1A19",
                                  alt: lighten(0.05, "#1B1A19"),
                                  wash: lighten(0.1, "#1B1A19"),
                                  reverse: "#FFF",
                                }
                              : undefined,
                          primary: festival.festival_color,
                          secondary: festival.festival_secondary_color,
                          tertiary: festival.festival_tertiary_color,
                        })}
                      >
                        <Disclaimer
                          activeTicketCategory={activeTicketCategory}
                          adultDisclaimer={
                            festival.adult_disclaimer &&
                            PrismicRichText.render(
                              festival.adult_disclaimer,
                              linkResolver,
                              serializer
                            )
                          }
                          seniorDisclaimer={
                            festival.child_youth_senior_disclaimer &&
                            PrismicRichText.render(
                              festival.child_youth_senior_disclaimer,
                              linkResolver,
                              serializer
                            )
                          }
                        />
                      </ThemeProvider>
                    </FlexCol>
                  )}
                </FlexGrid>
              </SectionWrapper>
              <Alternate
                title={
                  festival.contact_title &&
                  PrismicRichText.asText(festival.contact_title)
                }
                subtitle={
                  festival.contact_subtitle &&
                  PrismicRichText.asText(festival.contact_subtitle)
                }
                email={festival.ticket_email}
                phone={festival.ticket_phone}
                address={
                  festival.ticket_address &&
                  festival.ticket_address[0] &&
                  festival.ticket_address
                }
              />
            </>
          )}
        </>
      </ThemeProvider>
    </>
  );
}

export default FestivalTickets;

export const query = graphql`
  query ($id: String!) {
    festival: prismicFestival(id: { eq: $id }) {
      dataString
      uid
    }
    activeFestival: prismicActiveFestival {
      dataString
    }
  }
`;
