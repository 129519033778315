import React from "react";
import { RichText as PrismicRichText } from "prismic-reactjs";

import linkResolver from "src/utils/linkResolver";
import serializer from "src/utils/serializer";

import {
  Box,
  Button,
  FlexCol,
  H2,
  IlloWrapper,
  RichText,
  PrismicLink,
} from "src/components";

function TicketList({ tickets, ticketLink, illustrations }) {
  return (
    <>
      <IlloWrapper illustrations={illustrations} size={3} nw={3} se={1} ne={4}>
        <Box mx={["-7.5vw", null, 0]}>
          {tickets.map((ticket, index) => {
            if (ticket.primary.pass_title) {
              return (
                <FlexCol
                  bg={index % 2 === 0 ? "brand.secondary" : "brand.primary"}
                  py={[6, 7, 6]}
                  px={[4, 5, 6]}
                  key={"ticket" + index}
                >
                  <H2
                    children={PrismicRichText.asText(ticket.primary.pass_title)}
                  />
                  {ticket.primary.pass_description && (
                    <Box width={1} mt={4} mb={6}>
                      <RichText fontSize={1}>
                        {PrismicRichText.render(
                          ticket.primary.pass_description,
                          linkResolver,
                          serializer
                        )}
                      </RichText>
                    </Box>
                  )}
                  <Box>
                    {ticketLink && (
                      <Button
                        children={
                          ticket.primary.label
                            ? ticket.primary.label
                            : `Get Tickets`
                        }
                        as={PrismicLink}
                        link={ticket.primary.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        variant="defaultAlt"
                      />
                    )}
                  </Box>
                </FlexCol>
              );
            } else {
              return null;
            }
          })}
        </Box>
      </IlloWrapper>
    </>
  );
}

export default TicketList;
