import React from "react";

import { Box, FlexGrid, FlexRow, H6, Icon, Text } from "src/components";

import { mdiCheck } from "@mdi/js";

const Features = ({ title, features }) => (
  <Box>
    <Box mt={[0, null, null, 5]}>
      {title && <H6 children={title} mb={4} ml={5} />}
      {features && (
        <FlexGrid gutterX={[5]} gutterY={[2, 3]}>
          {features.map((feature, index) => (
            <FlexRow
              alignItems="flex-start"
              width={[1, 1 / 2, null, 1]}
              key={`feature` + index}
            >
              <div>
                <Icon
                  path={mdiCheck}
                  size={1.5}
                  css={`
                    width: 1.5rem !important;
                    height: 1.5rem !important;
                    fill: ${(props) => props.theme.colors.brand.primary};
                  `}
                />
              </div>
              <Text
                children={feature.feature}
                ml={2}
                fontSize={[1, 2]}
                color="text.body"
              />
            </FlexRow>
          ))}
        </FlexGrid>
      )}
    </Box>
  </Box>
);

export default Features;
