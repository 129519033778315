import React from "react";

import { Box, H6, RichText } from "src/components";

const Disclaimer = ({
  activeTicketCategory,
  adultDisclaimer,
  seniorDisclaimer,
}) => (
  <>
    {(activeTicketCategory === "Adult" ||
      activeTicketCategory === "Student / Youth / Senior") && (
      <>
        {((activeTicketCategory === "Adult" && adultDisclaimer) ||
          (activeTicketCategory === "Student / Youth / Senior" &&
            seniorDisclaimer)) && (
          <Box as="aside" mx={[5, null, null, 6]} color="text.default">
            {/* <H6 children={`Disclaimer`} mb={2} /> */}
            <RichText
              fontSize={[0, 1]}
              // css={`
              //   * {
              //     color: white !important;
              //   }
              // `}
            >
              {activeTicketCategory === "Adult" && <>{adultDisclaimer}</>}
              {activeTicketCategory === "Student / Youth / Senior" && (
                <>{seniorDisclaimer}</>
              )}
            </RichText>
          </Box>
        )}
      </>
    )}
  </>
);

export default Disclaimer;
