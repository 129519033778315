import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { RichText as PrismicRichText } from "prismic-reactjs";

import linkResolver from "src/utils/linkResolver";
import serializer from "src/utils/serializer";

import {
  Box,
  FlexGrid,
  H2,
  H6,
  RichText,
  SectionHeader,
  SectionWrapper,
  Text
} from "src/components";

const Intro = ({ title, subtitle, email, phone, address }) => (
  <SectionWrapper id="alternate" maxWidth={1} py={[8, 9, 10]} bg="bg.wash">
    {title && <SectionHeader heading={title} body={subtitle} />}
    <FlexGrid gutterX={[7]} gutterY={[5, 6]}>
      {address && (
        <Box width={[1, 1 / 2]}>
          <H6 children={`Address`} />
          <RichText
            children={PrismicRichText.render(address, linkResolver, serializer)}
            mt={2}
            fontSize={[2, 3]}
          />
        </Box>
      )}
      {phone && (
        <Box width={[1, 1 / 2]}>
          <H6 children={`Phone`} />
          {phone && (
            <Text
              children={phone}
              mt={2}
              fontSize={[2, 3]}
              css={`
                font-variant-numeric: tabular-nums;
              `}
            />
          )}
        </Box>
      )}
      {email && (
        <Box width={[1, 1 / 2]}>
          <H6 children={`Email`} />
          {email && <Text children={email} mt={2} fontSize={[2, 3]} />}
          <Text
            children={`Please do not send unsolicited artist materials to this address.`}
            fontSize={0}
            color="text.alt"
          />
        </Box>
      )}
    </FlexGrid>
  </SectionWrapper>
);

export default Intro;
