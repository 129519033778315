import React, { useState, useEffect } from "react";
import { rgba } from "polished";
import { RichText as PrismicRichText } from "prismic-reactjs";

import linkResolver from "src/utils/linkResolver";
import serializer from "src/utils/serializer";

import {
  Box,
  Button,
  FlexCol,
  FlexGrid,
  FlexRow,
  H1,
  H2,
  H6,
  IlloWrapper,
  RadioButton,
  RichText,
  PrismicLink,
  Text,
} from "src/components";

const TicketSection = ({
  ticketLink,
  title,
  tickets,
  activeTicket,
  setActiveTicket,
  activeTicketCategory,
  bg,
  color,
}) => (
  <FlexCol height="100%" pt={5} px={[5, 6]} pb={[5, 6]} bg={bg}>
    <H6 children={title} mb={5} textAlign="center" color={color} />
    <Box maxWidth={["100%", "80%"]} mx="auto">
      <FlexRow justifyContent="center" mb={3} flexWrap="wrap" mx={-5}>
        {tickets.map((ticket, index) => {
          if (ticket.primary.pass_title) {
            return (
              <H6
                children={PrismicRichText.asText(ticket.primary.pass_title)}
                ml={index > 0 ? [3, 4] : 0}
                mb={2}
                color={color}
                onClick={() => {
                  setActiveTicket(index);
                }}
                className={index === activeTicket ? "active" : ""}
                css={`
                  opacity: 0.5;
                  cursor: pointer;
                  &.active {
                    opacity: 1;
                    background-image: linear-gradient(
                      to bottom,
                      transparent 95%,
                      ${color} 0%
                    );
                  }
                `}
              />
            );
          } else {
            return null;
          }
        })}
      </FlexRow>
    </Box>
    {tickets.map((ticket, index) => {
      if (activeTicket === index) {
        return (
          <>
            {ticket.items.map((price, index) => {
              if (activeTicketCategory === price.age) {
                return (
                  <Box width={1} textAlign="center">
                    <H1
                      children={price.advance_price}
                      color={color}
                      mx="0.25em"
                      css={`
                        display: inline-block;
                        vertical-align: baseline;
                        font-variant-numeric: tabular-nums;
                        letter-spacing: -0.025em;
                      `}
                    />
                    {price.gate_price && (
                      <H2
                        children={price.gate_price}
                        color={color}
                        css={`
                          position: relative;
                          display: inline-block;
                          vertical-align: baseline;
                          font-variant-numeric: tabular-nums;
                          letter-spacing: -0.025em;
                          color: ${rgba(color, 0.5)};
                          &::after {
                            content: "";
                            position: absolute;
                            top: 50%;
                            right: 0;
                            left: 0;
                            width: 110%;
                            height: 2px;
                            background-color: ${color};
                            transform: translateY(-50%) rotate(-30deg);
                            transform-origin: center;
                          }
                        `}
                      />
                    )}
                  </Box>
                );
              } else {
                return null;
              }
            })}
            {ticket.primary.pass_description && (
              <Box width={1} mt={4} mb={6}>
                <RichText fontSize={0} textAlign="center" color={color}>
                  {PrismicRichText.render(
                    ticket.primary.pass_description,
                    linkResolver,
                    serializer
                  )}
                </RichText>
              </Box>
            )}
            <Box width={1} mt="auto">
              {((ticketLink && ticketLink.url) ||
                (ticket.primary.link && ticket.primary.link.url)) && (
                <Button
                  children={`Get tickets`}
                  width={1}
                  as={PrismicLink}
                  link={
                    ticketLink && ticketLink.url
                      ? ticketLink
                      : ticket.primary.link
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  variant={color === "#fff" ? "reverseAlt" : "defaultAlt"}
                />
              )}
            </Box>
          </>
        );
      } else {
        return null;
      }
    })}
  </FlexCol>
);

function TicketBlock({
  tickets,
  ticketLink,
  illustrations,
  map,
  activeTicketCategory,
  setActiveTicketCategory,
  childrenText,
}) {
  const [studentOptionAvailable, setStudentOptionAvailable] = useState(false);
  const [activeSingleDayTicket, setActiveSingleDayTicket] = useState(0);
  const [activeFestivalTicket, setActiveFestivalTicket] = useState(0);
  const [singleTickets, setSingleTickets] = useState([]);
  const [festivalTickets, setFestivalTickets] = useState([]);

  useEffect(() => {
    let singleTickets = [];
    let festivalTickets = [];
    tickets.forEach((ticket) => {
      if (ticket.primary.type === "1-Day Pass") {
        singleTickets.push(ticket);
      } else {
        festivalTickets.push(ticket);
      }
      ticket.items.forEach((price) => {
        if (price.age === "Student / Youth / Senior") {
          setStudentOptionAvailable(true);
        }
      });
    });
    setSingleTickets(singleTickets);
    setFestivalTickets(festivalTickets);
  }, []);

  return (
    <>
      <FlexCol
        mb={[6, 4]}
        alignItems="center"
        position="relative"
        style={{ zIndex: 10 }}
      >
        <RadioButton
          options={[].concat(
            studentOptionAvailable
              ? [
                  { option: "Adult" },
                  {
                    option: "Student / Youth / Senior",
                  },
                  { option: "Children" },
                ]
              : childrenText
              ? [{ option: "Adult" }, { option: "Children" }]
              : [{ option: "Adult" }]
          )}
          // .concat(map && map.url ? [{ option: "Map" }] : [])}
          value={activeTicketCategory}
          onChange={setActiveTicketCategory}
        />
      </FlexCol>
      <IlloWrapper illustrations={illustrations} size={3} nw={3} se={1} ne={4}>
        <Box mx={["-7.5vw", null, 0]}>
          <FlexGrid gutterY={[0]} height="100%">
            {activeTicketCategory !== "Children" &&
              activeTicketCategory !== "Map" &&
              festivalTickets.length > 0 && (
                <FlexCol width={[1, singleTickets.length > 0 ? 1 / 2 : 1]}>
                  <TicketSection
                    ticketLink={ticketLink}
                    title={"Festival Passes"}
                    tickets={festivalTickets}
                    activeTicket={activeFestivalTicket}
                    setActiveTicket={setActiveFestivalTicket}
                    activeTicketCategory={activeTicketCategory}
                    bg="brand.primary"
                    color="#fff"
                  />
                </FlexCol>
              )}
            {/* TEMP CHANGE FOR SUMMER SERENADES */}
            {activeTicketCategory !== "Children" &&
              activeTicketCategory !== "Map" &&
              singleTickets.length > 0 && (
                <FlexCol width={[1, festivalTickets.length > 0 ? 1 / 2 : 1]}>
                  <TicketSection
                    ticketLink={ticketLink}
                    title={"Select a day"}
                    tickets={singleTickets}
                    activeTicket={activeSingleDayTicket}
                    setActiveTicket={setActiveSingleDayTicket}
                    activeTicketCategory={activeTicketCategory}
                    bg="brand.secondary"
                    color="#fff"
                  />
                </FlexCol>
              )}
            {activeTicketCategory === "Children" && (
              <FlexCol
                width={1}
                bg="brand.primary"
                justifyContent="center"
                alignItems="center"
                minHeight={400}
                p={[3, 4]}
              >
                <Text
                  fontSize={3}
                  children={childrenText}
                  color="text.default"
                  textAlign="center"
                  maxWidth={600}
                />
              </FlexCol>
            )}
            {activeTicketCategory === "Map" && (
              <FlexCol
                width={1}
                bg="brand.primary"
                justifyContent="center"
                alignItems="center"
                minHeight={400}
                p={[3, 4]}
              >
                <Box p={[3, 4]}>
                  {map && (
                    <img
                      src={map.url}
                      alt={map.alt}
                      width="100%"
                      height="auto"
                    />
                  )}
                </Box>
              </FlexCol>
            )}
          </FlexGrid>
        </Box>
      </IlloWrapper>
    </>
  );
}

export default TicketBlock;
